<template>
	<div class="content">
		<web-header></web-header>
		<div class="mineContent">
			<div>
				<div class="navMenu">
					<div class="menuLeft">
						<p style="padding:19px 0;font-size:18px">个人中心</p>
						<div v-for="(item,idx) in tabPosition" :key="idx" class="tabPositionItem"
							@click="checkedItem(idx)" :class="active===idx? 'style':''">
							<i :class="item.icon" class="icon" :style="active===idx? styleObject:''"></i>
							<span :style="active===idx? styleObject:''">{{item.name}}</span>
						</div>
					</div>
					<div class="navRight">
						<EditInfo class="animate__animated animate__fadeInRight" v-if='active == 0' />
						<Mytraining class="animate__animated animate__fadeInRight" v-if='active == 1' />
						<Live class="animate__animated animate__fadeInRight" v-if='active == 2' />
						<Order class="animate__animated animate__fadeInRight" v-if='active == 3' />
						<WrongQuestion class="animate__animated animate__fadeInRight" v-if='active == 4' />
						<ArchiveCertificate class="animate__animated animate__fadeInRight" v-if='active == 5' />
						<MyInvoice class="animate__animated animate__fadeInRight" v-if='active == 6' />
					</div>
				</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import EditInfo from './i-editInfo.vue'
	import Mytraining from './i-myTraining.vue'
	import Live from './i-live.vue'
	import Order from './i-order.vue'
	import WrongQuestion from './i-wrongQuestion.vue'
	import ArchiveCertificate from './i-archiveCertificatea.vue'
	import MyInvoice from './i-mysInvoice.vue'
	import {
		mapState
	} from 'vuex'
	export default {
		components: {
			WebFooter,
			webHeader,
			EditInfo,
			Mytraining,
			Live,
			Order,
			WrongQuestion,
			ArchiveCertificate,
			MyInvoice
		},
		data() {
			return {
				tabPosition: [{
						name: '编辑资料',
						icon: 'el-icon-edit-outline'
					},
					{
						name: '我的培训',
						icon: 'el-icon-reading'
					},
					{
						name: '我的直播',
						icon: 'el-icon-video-camera'
					},
					{
						name: '我的订单',
						icon: 'el-icon-document'
					},
					{
						name: '我的错题库',
						icon: 'el-icon-notebook-2',
						mine: 'true'
					},
					{
						name: '档案证书',
						icon: 'el-icon-notebook-1'
					},
					{
						name: '我的发票',
						icon: 'el-icon-document-remove'
					},
				],
				active: 0,
				styleObject: {
					color: 'red',
				},
			}

		},
		created() {
			if (!this.isLogin) {
				this.$message.error('暂未登录，2S后自动跳转到登录页面')
				setTimeout(() => {
					this.$router.push({
						path: '/login',
					})
				}, 2000);
			}
			if (this.$route.query.infoIndex) {
				// this.active = 3
				this.active = parseInt(this.$route.query.infoIndex)
			} else {
				this.active = sessionStorage.getItem('profileMenuIndex') ? Number(sessionStorage.getItem(
					'profileMenuIndex')) : 0
			}
		},
		computed: {
			...mapState(['isLogin'])
		},
		methods: {
			checkedItem(i) {
				this.active = i
				sessionStorage.setItem('profileMenuIndex', i)
			}
		}

	}
</script>

<style lang="less" scoped>
	// .content{
	// 	display: flex;
	// 	min-height: 100vh;
	// }
	.mine {
		margin-left: 15px;
	}

	.style {
		border-left: 4px solid red !important;
		background-color: #EEEEEE !important;

	}

	.navRight {
		margin-left: 30px;
	}

	.mineContent {
		width: 100%;
		background: #EEEEEE;
		padding-top: 30px;

		.navMenu {
			width: 1200px;
			margin: auto;
			display: flex;

			.menuLeft {
				width: 226px;
				text-align: center;
				background: #fff;
				margin-bottom: 20px;
				height: 800px;

				.tabPositionItem {
					height: 56px;
					line-height: 56px;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #666666;
					cursor: pointer;
					border-left: 4px solid transparent;

					.icon {
						display: inline-block;
						margin-right: 10px;
					}
				}
			}

		}

	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		padding-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
