<template>
	<div class="editInfoContent">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="全部订单" name="first">
				<div class="info">
					<div class="infoHeader">
						<el-row>
							<el-col :span="10">
								<div class="grid-content bg-purple infoHeaderItem">订单详情</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple-light infoHeaderItem">金额</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple infoHeaderItem">状态</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple-light infoHeaderItem">操作</div>
							</el-col>
						</el-row>
					</div>
					<div v-if="list.length > 0">
						<div v-for="(item,idx) in list " :key="idx">
							<div class="infoItem">
								<p style="margin-right:25px">{{item.updateTime ? item.updateTime : item.createTime}}</p>
								<el-skeleton />
								<p>订单编号：{{item.orderCode}}</p>
							</div>

							<el-row class="infoOrder">
								<el-col :span="10">
									<div class="grid-content bg-purple orderImg">
										<div style="margin-right:20px">
											<img v-if="item.isCollective == 0" :src="item.coverPicture" alt="">
											<img v-else src="../../assets/index/2.png" alt="">
										</div>
										<div>
											<div v-if="item.courseName" style="font-size: 18px;margin-bottom:27px">
												{{item.courseName}}
											</div>
											<div style="font-size: 14px;">科目
												：{{item.classifyName?item.classifyName:'集体报名' }}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple-light flex">
										<p>{{item.unitPrice}}</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple flex">
										<p>{{
                    item.status === 0 ? '取消支付' : item.status === 1 ? '待支付' : item.status === 2 ? '已支付' :
                    item.status === 3 ? '已退款' :item.status === 4 ? '正在退款' : item.status === 5 ? '退款审核中' :
                    item.status === 6 ? '审核失败,联系客服' : item.status === 7 ? '取消退款' : ''
                    }}</p>
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple-light ">
										<div class="caozuo">
											<div v-if='item.status === 1'>
												<el-button class="bnt1" @click="goPay(item)">去付款</el-button>
												<div style="font-size:14px;cursor:pointer;"
													@click="cancellationOrder(item)">取消订单</div>
											</div>
											<div v-if='item.status === 2 && item.graduationStatus !== 2 '>
												<el-button @click="refund(item)" class="bnt1">申请退款</el-button>
											</div>
											<div v-if='item.status === 2 && item.graduationStatus === 2'>
												<el-button  class="bnt2">已结业</el-button>
											</div>
											<div v-if='item.status === 4'>
												<el-button class="bnt2">退款中</el-button>
											</div>
											<div style="margin-top:18px" v-if='item.status === 3'>
												完成
											</div>
											<div v-if='item.status === 5 '>
												<el-button class="bnt2">已申请</el-button>
											</div>
											<!-- <div v-if='item.status === 6 || item.status === 7'>
												<el-button class="bnt2">重新申请</el-button>
											</div> -->
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div class="noTeacter" v-else-if="list.length === 0">
						<div class="noTeacter_img">
							<img src="../../assets/imgs/4.png" alt="">
							<div>您还没有订单哦~~</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="待付款" name="second">
				<div class="info">
					<div class="infoHeader">
						<el-row>
							<el-col :span="10">
								<div class="grid-content bg-purple infoHeaderItem">订单详情</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple-light infoHeaderItem">金额</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple infoHeaderItem">状态</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple-light infoHeaderItem">操作</div>
							</el-col>
						</el-row>
					</div>
					<div v-if="list.length > 0">
						<div v-for="(item,idx) in list " :key="idx">
							<div class="infoItem">
								<p style="margin-right:25px">{{item.updateTime ? item.updateTime : item.createTime}}</p>
								<p>订单编号：{{item.orderCode}}</p>
							</div>

							<el-row class="infoOrder">
								<el-col :span="10">
									<div class="grid-content bg-purple orderImg">
										<div style="margin-right:20px">
											<img v-if="item.isCollective == 0" :src="item.coverPicture" alt="">
											<img v-else src="../../assets/index/2.png" alt="">
										</div>
										<div>
											<div v-if="item.classifyName" style="font-size: 18px;margin-bottom:27px">
												{{item.courseName}}
											</div>
											<div style="font-size: 14px;">科目
												：{{item.classifyName?item.classifyName:'集体报名'}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple-light flex">
										<p>{{item.unitPrice}}</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple flex">
										<p>{{
                                           item.status === 0 ? '取消支付' : item.status === 1 ? '待支付' : item.status === 2 ? '已支付' :
                                           item.status === 3 ? '已退款' :item.status === 4 ? '正在退款' : item.status === 5 ? '退款审核中' :
                                           item.status === 6 ? '退款失败' : item.status === 7 ? '取消退款' : ''
                                         }}</p>
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple-light ">
										<div class="caozuo">
											<div v-if='item.status === 1'>
												<el-button class="bnt1" @click="goPay(item)">去付款</el-button>
												<div style="font-size:14px;cursor:pointer;"
													@click="cancellationOrder(item)">取消订单</div>
											</div>
											<div v-if='item.status === 2'>
												<el-button @click="refund(item)" class="bnt1">申请退款</el-button>
											</div>
											<div style="margin-top:18px" v-if='item.status === 3'>
												完成
											</div>
											<div v-if='item.status === 5 || item.status === 4'>
												<el-button class="bnt2">已申请</el-button>
											</div>
											<div v-if='item.status === 6'>
												<el-button @click="refund(item)" class="bnt2">重新申请</el-button>
											</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div class="noTeacter" v-else-if="list.length === 0">
						<div class="noTeacter_img">
							<img src="../../assets/imgs/4.png" alt="">
							<div>您还没有订单哦~~</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="已支付" name="third">
				<div class="info">
					<div class="infoHeader">
						<el-row>
							<el-col :span="10">
								<div class="grid-content bg-purple infoHeaderItem">订单详情</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple-light infoHeaderItem">金额</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple infoHeaderItem">状态</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple-light infoHeaderItem">操作</div>
							</el-col>
						</el-row>
					</div>
					<div v-if="list.length > 0">
						<div v-for="(item,idx) in list " :key="idx">
							<div class="infoItem">
								<p style="margin-right:25px">{{item.updateTime ? item.updateTime : item.createTime}}</p>
								<p>订单编号：{{item.orderCode}}</p>
							</div>

							<el-row class="infoOrder">
								<el-col :span="10">
									<div class="grid-content bg-purple orderImg">
										<div style="margin-right:20px">
											<img v-if="item.isCollective == 0" :src="item.coverPicture" alt="">
											<img v-else src="../../assets/index/2.png" alt="">
										</div>
										<div>
											<div v-if="item.classifyName" style="font-size: 18px;margin-bottom:27px">
												{{item.courseName}}
											</div>
											<div style="font-size: 14px;">科目
												：{{item.classifyName?item.classifyName:'集体报名'}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple-light flex">
										<p>{{item.unitPrice}}</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple flex">
										<p>{{
                    item.status === 0 ? '取消支付' : item.status === 1 ? '待支付' : item.status === 2 ? '已支付' :
                    item.status === 3 ? '已退款' :item.status === 4 ? '正在退款' : item.status === 5 ? '退款审核中' :
                    item.status === 6 ? '退款失败' : item.status === 7 ? '取消退款' : ''
                    }}</p>
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple-light ">
										<div class="caozuo">
											<div v-if='item.status === 1'>
												<el-button class="bnt1" @click="goPay(item)">去付款</el-button>
												<div style="font-size:14px;cursor:pointer;"
													@click="cancellationOrder(item)">取消订单</div>
											</div>
											<div v-if='item.status === 2'>
												<el-button @click="refund(item)" class="bnt1">申请退款</el-button>
											</div>
											<div style="margin-top:18px" v-if='item.status === 3'>
												完成
											</div>
											<div v-if='item.status === 5 || item.status === 4'>
												<el-button class="bnt2">已申请</el-button>
											</div>
											<div v-if='item.status === 6'>
												<el-button class="bnt2" @click="refund(item)">重新申请</el-button>
											</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div class="noTeacter" v-else-if="list.length === 0">
						<div class="noTeacter_img">
							<img src="../../assets/imgs/4.png" alt="">
							<div>您还没有订单哦~~</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="退款" name="fourth">
				<div class="info">
					<div class="infoHeader">
						<el-row>
							<el-col :span="10">
								<div class="grid-content bg-purple infoHeaderItem">订单详情</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple-light infoHeaderItem">金额</div>
							</el-col>
							<el-col :span="4">
								<div class="grid-content bg-purple infoHeaderItem">状态</div>
							</el-col>
							<el-col :span="6">
								<div class="grid-content bg-purple-light infoHeaderItem">操作</div>
							</el-col>
						</el-row>
					</div>
					<div v-if="list.length > 0">
						<div v-for="(item,idx) in list " :key="idx">
							<div class="infoItem">
								<p style="margin-right:25px">{{item.updateTime ? item.updateTime : item.createTime}}</p>
								<p>订单编号：{{item.orderCode}}</p>
							</div>

							<el-row class="infoOrder">
								<el-col :span="10">
									<div class="grid-content bg-purple orderImg">
										<div style="margin-right:20px">
											<img v-if="item.isCollective == 0" :src="item.coverPicture" alt="">
											<img v-else src="../../assets/index/2.png" alt="">
										</div>
										<div>
											<div v-if="item.classifyName" style="font-size: 18px;margin-bottom:27px">
												{{item.courseName}}
											</div>
											<div style="font-size: 14px;">科目
												：{{item.classifyName?item.classifyName:'集体报名'}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple-light flex">
										<p>{{item.unitPrice}}</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div class="grid-content bg-purple flex">
										<p>{{
                    item.status === 0 ? '取消支付' : item.status === 1 ? '待支付' : item.status === 2 ? '已支付' :
                    item.status === 3 ? '已退款' :item.status === 4 ? '正在退款' : item.status === 5 ? '退款审核中' :
                    item.status === 6 ? '退款失败' : item.status === 7 ? '取消退款' : ''
                    }}</p>
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple-light ">
										<div class="caozuo">
											<div v-if='item.status === 1'>
												<el-button class="bnt1" @click="goPay(item)">去付款</el-button>
												<div style="font-size:14px;cursor:pointer;"
													@click="cancellationOrder(item)">取消订单</div>
											</div>
											<div v-if='item.status === 2'>
												<el-button @click="refund(item)" class="bnt1">申请退款</el-button>
											</div>
											<div style="margin-top:18px" v-if='item.status === 3'>
												完成
											</div>
											<div v-if='item.status === 4'>
												<el-button class="bnt2">退款中</el-button>
											</div>
											<div v-if='item.status === 6 '>
												<el-button class="bnt2" @click="refund(item)">重新申请</el-button>
											</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div class="noTeacter" v-else-if="list.length === 0">
						<div class="noTeacter_img">
							<img src="../../assets/imgs/4.png" alt="">
							<div>您还没有订单哦~~</div>
						</div>
					</div>
				</div>
			</el-tab-pane>

		</el-tabs>

	</div>
</template>

<script>
	export default {
		name: 'Order',
		data() {
			return {
				activeName: 'first',
				list: []

			};
		},
		created() {
			//获取订单列表
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post('individualOrders', {
					order: 1,
					type: 0
				}).then(res => {
					if (res.code === 200) {
						this.list = res.data
						// console.log(res);
						// res.data.map(item=>{
						// 	if(item.status==='1'){
						//       this.list.push(item)
						// 	}
						// })
						// 		if (e.index === '1') {
						// 	//获取代付款订单列表
						// 	this.$http.post('individualOrders', {
						// 		order: 1,
						// 		type: 1
						// 	}).then(res => {
						// 		if (res.code === 200) {
						// 			this.list = res.data
						// 			console.log(res,'res===><<');
						// 		}
						// 	})
						// }

					}
				})
			},
			handleClick(e) {
				if (e.index === '0') {
					//获取订单列表
					this.$http.post('individualOrders', {
						order: 1,
						type: 0
					}).then(res => {
						if (res.code === 200) {
							this.list = res.data
						}
					})
				}
				if (e.index === '1') {
					//获取代付款订单列表
					this.$http.post('individualOrders', {
						order: 1,
						type: 1
					}).then(res => {
						if (res.code === 200) {
							this.list = res.data

						}
					})
				}
				if (e.index === '2') {
					//获取已经支付订单列表
					this.$http.post('individualOrders', {
						order: 1,
						type: 2
					}).then(res => {
						if (res.code === 200) {
							this.list = res.data
						}
					})
				}
				if (e.index === '3') {
					//获取退款订单列表
					this.$http.post('individualOrders', {
						order: 1,
						type: 3
					}).then(res => {
						if (res.code === 200) {
							this.list = res.data
						}
					})
				}
			},
			// 申请退款
			refund(e) {

				if (!e.unitPrice) return this.$message('金额为零无法退款！')
				this.$confirm('是否确认退款?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post('refund', {
						id: e.id
					}).then(res => {
						if (res.code === 200) {
							this.$message({
								type: 'success',
								message: '退款成功!'
							});

							this.handleClick({
								index: '3'
							})

						} else {
							this.$message.error(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消退款'
					});
				});

			},
			// 去付款
			goPay(e) {

				if (e.isCollective === 0) {
					// type为表示订单传到支付界面
					// 保存id，防止刷新
					localStorage.setItem('cousreId', e.orderCode)
					this.$router.push({
						path: '/trainingProgram/paymentOrder',
						query: {
							index: 3,
							type: 3,
							data: e,
							typeId: 1,
							isCollective: 0
						}
					})
				} else if (e.isCollective === 1) {
					localStorage.setItem('cousreId', e.orderCode)
					this.$router.push({
						path: '/trainingProgram/paymentOrder',
						query: {
							index: 3,
							type: 3,
							data: e,
							typeId: 1,
							isCollective: 1
						}
					})
				}
			},
			// 取消订单
			cancellationOrder(e) {
				this.$confirm('是否确认取消订单?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post('cancellationOrder', {
						id: e.id
					}).then(res => {
						if (res.code === 200) {
							this.$message.success('订单取消成功')
							console.log(e, 'eeeeeeeeeee');

							this.handleClick({
								index: '1'
							})

						} else {
							this.$message.error(res.message)
						}

					})
				})
			}
		}

	}
</script>

<style lang="less" scoped>
	.editInfoContent {
		width: 974px;
		height: 800px;
		background-color: #fff;

		/deep/ .el-tabs__item {
			font-size: 24px;
			padding-bottom: 19px;
			padding-left: 21px !important;
			line-height: 63px;
			height: 63px;
		}

		/deep/ .el-tabs__nav-scroll {
			height: 63px;
		}

		/deep/ .el-tabs__active-bar {
			// left: 24px;
			background-color: #F02610;
		}

		/deep/ .el-tabs__item:hover {
			color: #F02610;
		}

		/deep/.el-tabs__item.is-active {
			color: #F02610;
		}

		.noData {
			font-size: 14px;
			color: #ccc;
			padding: 300px 400px
		}
	}

	.info {
		overflow: auto;
		height: 700px;
		padding: 0 25px;

		.infoHeader {
			background: #EEEEEE;
			font-size: 16px;
			text-align: center;

			.infoHeaderItem {
				padding: 20px 0 15px 0;
			}
		}

		.infoItem {
			border: 1px solid #e5e5e5;
			padding: 18px 15px;
			display: flex;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
		}

		.flex {
			line-height: 96px;
			font-size: 16px;
		}

		.infoOrder {
			border: 1px solid #EEEEEE;
			padding: 15px;
			text-align: center;

			.orderImg {
				display: flex;
				align-items: center;

				img {
					width: 160px;
					height: 90px;
				}

			}

			.caozuo {
				padding: 16px
			}

			.bnt1 {
				margin: 5px 0;
				color: #E51E0B;
				border: 1px solid #F02610;
				font-size: 16px;
				font-weight: 400;
			}

			.bnt2 {
				margin: 5px 0;
				background: #E51E0B;
				font-size: 16px;
				font-weight: 400;
				color: #fff;
			}
		}
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		padding-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
