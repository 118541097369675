<template>
	<div class="editInfoContent">
		<!-- tab -->
		<div class="tab">
			<div class="tab_l">
				<div v-for="(item,index) in tabList" :key="index"
					:class="['tab_item',{'active_item': currentTabIndex === index}]" @click="handleTabChange(index)">
					{{item.name}}
				</div>
			</div>
			<div class="tab_r">
				<el-select v-model="yearsValue" placeholder="请选择年份" clearable>
					<el-option v-for="(item,index) in yearsOptions" :key="index" :label="item" :value="item" />
				</el-select>
			</div>
		</div>
		<!-- 盒子 -->
		<div class="content">
			<!-- 表头 -->
			<el-row class="table_head">
				<el-col :span="1">
					<div class="table_head_th">ID</div>
				</el-col>
				<el-col :span="4">
					<div class="table_head_th">课程名称</div>
				</el-col>
				<el-col :span="4">
					<div class="table_head_th">课程分类</div>
				</el-col>
				<el-col :span="4">
					<div class="table_head_th">培训时间</div>
				</el-col>
				<el-col :span="2">
					<div class="table_head_th">学时(分)数</div>
				</el-col>
				<el-col :span="3">
					<div class="table_head_th">学习进度</div>
				</el-col>
				<el-col :span="3" v-if="currentTabIndex === 0">
					<div class="table_head_th">考试类型</div>
				</el-col>
				<el-col :span="3">
					<div class="table_head_th" v-if="currentTabIndex === 1">考试得分</div>
				</el-col>
				<el-col :span="3" v-if="currentTabIndex === 0">
					<div class="table_head_th">考试时间</div>
				</el-col>
				<el-col :span="3" v-if="currentTabIndex === 1">
					<div class="table_head_th">状态</div>
				</el-col>
			</el-row>
			<!-- 表体 -->
			<div class="table_body" v-if="tableList.length > 0">
				<div class="table_item" v-for="(item,index) in tableList" :key="index">
					<!-- 数据 -->
					<el-row class="table_row">
						<el-col :span="1">
							<div class="table_row_th">{{index+1}}</div>
						</el-col>
						<el-col :span="4">
							<div class="table_row_th">{{item.name}}</div>
						</el-col>
						<el-col :span="4">
							<div class="table_row_th">{{item.classifyName}}</div>
						</el-col>
						<el-col :span="4">
							<div class="table_row_th">
								<span>{{item.studyStartTime}}</span>至<span>{{item.studyEndTime}}</span>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="table_row_th">{{item.classHours}}</div>
						</el-col>
						<el-col :span="3">
							<div class="table_row_th">{{item.studyCchedule}}%</div>
						</el-col>
						<el-col :span="3" v-if="currentTabIndex === 0">
							<div v-if="!item.examType" class="table_row_th">无需考试</div>
							<div v-if="item.examType===1" class="table_row_th">自主考试</div>
							<div v-if="item.examType===2" class="table_row_th">统一考试</div>
						</el-col>
							<el-col :span="3" v-if="currentTabIndex === 1">
						<div class="table_row_th">{{!item.examType?'/':item.dto.score}}</div>
						</el-col>
						<el-col :span="3" v-if="currentTabIndex === 0">
							<div class="table_row_th">{{item.dto.examTime ? item.dto.examTime : '/'}}</div>
						</el-col>
						<el-col :span="3" v-if="currentTabIndex === 1">
							<div v-if="item.examType" class="table_row_th">
								<span v-if="item.dto.passStatus === 0">未通过</span>
								<span v-else-if="item.dto.passStatus === 1">已通过</span>
								<span v-else-if="item.dto.passStatus === 2">待阅卷</span>
							</div>
							<div v-if="!item.examType" class="table_row_th">/</div>
						</el-col>
					</el-row>
					<!-- 按钮组 正在上课 -->
					<div class="btn_group" v-if="currentTabIndex === 0">
						<!-- 统一考试   countDownMinute 倒计时大于0 -->
						<!-- <el-button class="btn7" v-if="item.examType === 2 && item.dto && item.dto.countDownMinute >0">
							倒计时:{{handleCountdown(item.dto.countDownMinute)}}</el-button> -->
							<el-button class="btn7" v-if="item.examType === 2 && item.dto && item.dto.countDownMinute >0">
							倒计时:<count-down color="#FFFFFF" font-size="14" separator-size="10" bg-color="#E51E0B" 
									separator-color="#FFFFFF" :timestamp="item.dto.countDownMinute"></count-down></el-button>
							<!-- <count-down></count-down> -->
						<!--  item.dto.status： 0申请考试 1去考试 2待阅卷 3考试通过 4重新考试 5考试已结束 6无法申请 -->
						<el-button class="btn5" v-if="item.dto && item.dto.status == 0" @click="handleApplyExam(item)">
							申请考试</el-button>
						<el-button class="btn5" v-else-if="item.dto && item.dto.status == 1"
							@click="handleEnterExam(item)">去考试</el-button>
							<el-button class="btn8" v-else-if="item.dto && item.dto.status == 2">待阅卷</el-button>
							<el-button class="btn6" v-else-if="item.dto && item.dto.status == 3">考试通过</el-button>
						<el-button class="btn5" v-else-if="item.dto && item.dto.status == 4"
							@click="handleApplyExam(item)">重新申请考试</el-button>
						<el-button class="btn6" v-else-if="item.dto && item.dto.status == 5">考试已结束</el-button>
						<el-button class="btn6" v-else-if="item.dto && item.dto.status == 6">无法申请</el-button>
						<!-- 学习进度 item.studyCchedule-->
						<el-button class="btn7" v-if="item.graduationStatus === 0" @click="handleStartLearning(item)">
							开始学习</el-button>

						<!-- <el-button class="btn8" v-if="item.dto.status == 2 ">待阅卷</el-button> -->
						<el-button class="btn4" v-if="item.graduationStatus==1" @click="handleStartLearning(item)">继续学习
						</el-button>
						<el-button class="btn7" @click="handleChangeCourse(item.orderId)"
							v-if="item.studyCchedule < 100">更换课程</el-button>
						<el-button class="btn3" @click="handleStartLearning(item)">查看详情</el-button>
						<el-dropdown trigger="click">
							<el-button class="btn2" @click="getCourseChapter(item.id)">课后练习</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="handlePracticeClass(it)" v-for="(it,i) in sectionList"
									:key="i">
									<span>{{it.name}}</span>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-button class="btn1" v-if="item.feelExperiences===1" @click="handleLearningExperience(item)">学习心得
						</el-button>
					</div>
					<!-- 按钮组 已结课 -->
					<div class="btn_group" v-if="currentTabIndex === 1">
						<!-- <el-button class="btn2" @click="handleViewTest(item.dto.paperId)">查看试卷</el-button> -->
						<!-- <el-button class="btn7" @click="handlePrintCertificate(item.id)">打印证书</el-button> -->
						<a class="btn7 a_btn" :href="item.path" :download="item.name" target="_blank"
							v-if="isIssueCertificate(item.isIssue,item.isExam,item.dto)">打印证书</a>
						<el-button class="btn6" v-if="item.graduationStatus === 2 ">学习完成</el-button>
						<el-button class="btn3" @click="handleStartLearning(item)">查看详情</el-button>
						<el-button class="btn1" v-if="item.feelExperiences===1" @click="handleLearningExperience(item)">学习心得</el-button>
					</div>
				</div>
			</div>
			<div class="noTeacter" v-else-if="tableList.length === 0">
				<div class="noTeacter_img">
					<img src="../../assets/imgs/4.png" alt="">
					<div>暂无数据~</div>
				</div>
			</div>
		</div>
		<!-- 学习心得弹窗 -->
		<el-dialog title="学习心得" :visible.sync="dialogVisible" width="50%" :modal='false'>
			<div>
				<el-upload class="upload-demo" drag :action="actionUrl" :file-list="fileList"
					:before-upload="beforeLearningExperienceUpload" :on-change="handleLearningExperienceChange"
					:headers="{token:token}" :data={courseId:orderList.id} :multiple="false">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传doc/docx文件</div>
				</el-upload>
			</div>
			<div slot="footer" class="dialog-footer" style="text-align:center">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleDownload">下载学习心得</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import countDown from '../../components/countDown.vue';
	export default {
		name: 'Mytraining',
		data() {
			return {
				actionUrl: process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_Course1 + 'userCourse/add',
				currentTabIndex: 0, //当前tab索引
				tabList: [{
					type: 0,
					name: '正在上课'
				}, {
					type: 1,
					name: '已结课'
				}], //tab数据
				tableList: [], //页面数据
				yearsOptions: [], //年份数据
				yearsValue: '', //选中的年份
				sectionList: [], //章节数据
				orderList: {},
				dialogVisible: false,
				fileList: [], //学习心得文件
			};
		},
		components:{countDown},
		created() {
			this.getMyTrainYears();
			this.getMyTrain()
		},
		watch: {
			yearsValue(val) {
				this.getMyTrain()
			},
		},
		computed: {
			...mapState(['token'])
		},
		methods: {
			// 判断是否显示下载证书按钮
			isIssueCertificate(isIssue, isExam, dto) {
				if (!isIssue) return false
				if (isExam) {
					if (dto && dto.passStatus === 0) return false
					if (dto && dto.passStatus !== 0) return true
				} else {
					return true
				}
			},
			// tab切换
			handleTabChange(i) {
				this.currentTabIndex = i;
				this.yearsValue = '';
				this.tableList = [];
				this.getMyTrain()
			},
			// 计算倒计时
			handleCountdown(StatusMinute) {
				var day = parseInt(StatusMinute / 60 / 24);
				var hour = parseInt(StatusMinute / 60 % 24);
				var min = parseInt(StatusMinute % 60);
				StatusMinute = "";
				if (day > 0) {
					StatusMinute = day + "天";
				}
				if (hour > 0) {
					StatusMinute += hour + "小时";
				}
				if (min > 0) {
					StatusMinute += parseFloat(min) + "分钟";
				}
				//三元运算符 传入的分钟数不够一分钟 默认为0分钟，else return 运算后的StatusMinute 
				return StatusMinute == "" ? "0分钟" : StatusMinute;
			},
			// 学习心得上传前验证
			beforeLearningExperienceUpload(file) {
				// 截取文件后缀名
				let fileName = file.name;
				let first = fileName.lastIndexOf("."); //取到文件名开始到最后一个点的长度
				let filesuffix = fileName.substring(first + 1, fileName.length); //截取获得后缀名
				if (filesuffix === 'docx' || filesuffix === 'doc') {
					return true
				} else {
					this.$message.error('只能上传doc/docx格式!');
					return false
				}
			},
			// 学习心得上传
			handleLearningExperienceChange(file) {
				if (file.status === "ready") return
				if (file.response && file.response.code == 200) {
					this.$message.success('上传成功')
					setTimeout(() => {
						this.dialogVisible = false
					}, 1500);
				} else if (file.response && file.response.code != 200) {
					this.$message.error(file.response.message)
					this.fileList = []
				} else {
					this.$message.error('上传失败')
					this.fileList = []
				}
			},
			// 下载学习心得
			handleDownload() {
				if (!this.orderList.studyNotesVo || !this.orderList.studyNotesVo.courseNotes) return this.$message.warning(
					'该课程您还未上传学习心得')
				let dload = document.createElement("a");
				let url = this.orderList.studyNotesVo.courseNotes;
				let first = url.lastIndexOf("/");
				let filesuffix = url.substring(first + 1, url.length); //截取获得后缀名
				dload.download = filesuffix; // 设置下载的文件名，默认是'下载'
				dload.href = url;
				document.body.appendChild(dload);
				dload.click();
				dload.remove(); // 下载之后把创建的元素删除
			},
			// 获取章节信息 
			getCourseChapter(id) {
				this.sectionList = [];
				this.$http.post('getCourseSectionById', {
					id
				}).then(res => {
					if (res.code === 200) {
						this.sectionList = res.data;
						if (res.data.length == 0) {
							this.$message.warning('该课程暂无课后章节')
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 申请考试
			handleApplyExam(e) {
				this.$http.post('applyExam', {
					courseId: e.id
				}).then(res => {
					if (res.code === 200) {
						this.$message.success('申请成功')
						this.getMyTrain()
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 去考试，进入考试
			handleEnterExam(e) {
				localStorage.setItem('perId', e.dto.paperId)
				this.$router.push({
					path: '/examination/finalTest',
					query: {
						data: e
					}
				})
			},
			// 进入课后练习
			handlePracticeClass(e) {
				localStorage.setItem('zjieId', e.id)
				localStorage.setItem('coureId', e.courseId)

				if (e.studyTime === 100) {
					this.$http.post('mineTest', {
						chapterId: e.id,
						courseId: e.courseId
					}).then(res => {
						if (res.code == 200) {
							this.$router.push({
								path: '/examination/afterClassTest'
							})
						} else {
							this.$message.warning(res.message)
						}
					})
				} else {
					this.$message.error('您还没有学完此章节，快去学习吧')
				}
			},
			// 查看试卷
			handleViewTest(id) {
				localStorage.setItem('perId', id)
				this.$router.push({
					path: '/examination/viewTest'
				})
			},
			// 打印证书
			handlePrintCertificate(id) {},
			// 点击学习心得按钮
			handleLearningExperience(it) {
				this.orderList = it
				this.dialogVisible = true;

				if (it.studyNotesVo && it.studyNotesVo.courseNotes) {
					let url = it.studyNotesVo.courseNotes;
					let first = url.lastIndexOf("/");
					let filesuffix = url.substring(first + 1, url.length); //截取获得后缀名
					this.fileList = [{
						name: filesuffix,
						url
					}]
				} else {
					this.fileList = []
				}
			},
			// 开始学习
			handleStartLearning(it) {
				this.$router.push({
					path: '/trainingProgram/courseDetails',
					query: {
						id: it.id,
						index: 3,
						orderId: it.orderId
					}
				})
			},
			// 我的错题库
			wrong() {
				this.$router.push({
					path: '/personalCenter',
					query: {
						index: 3
					}
				})
			},
			// 
			/**   接口请求 */
			// 获取培训年份
			getMyTrainYears() {
				this.$http.post('getMyTrainYears').then(res => {
					if (res.code == 200) {
						this.yearsOptions = res.data
					}
				})
			},
			// 获取我的培训
			getMyTrain() {
				const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				this.$http.post('myTrainApi', {
					type: this.currentTabIndex,
					year: this.yearsValue
				}).then(res => {
					loading.close()
					if (res.code === 200) {

						this.tableList = res.data

					} else {
						this.tableList = [];
						this.$message.error(res.message)
					}
				}).catch(err => {
					loading.close()
				})
			},
			// 更换课程
			handleChangeCourse(orderId) {
				this.$http.post('changeCourse', {
					orderId
				}).then(res => {
					if (res.code == 200) {
						this.getMyTrain()
						this.$message.success(res.data)
					} else {
						this.$message.error(res.message)
					}
				})
			},
		}

	}
</script>

<style lang="less" scoped>
	.editInfoContent {
		width: 974px;
		height: 800px;
		background-color: #fff;
	}

	.tab {
		border-bottom: 1px solid #EEEEEE;
		height: 64px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.tab_l {
			display: flex;
			align-items: center;

			.tab_item {
				font-size: 24px;
				color: #333333;
				width: 142px;
				text-align: center;
				height: 64px;
				line-height: 64px;
				cursor: pointer;
			}

			.active_item {
				font-weight: bold;
				color: #E51E0B;
				border-bottom: 2px solid #E51E0B;
				box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
			}
		}

		.tab_r {
			margin-right: 30px;
		}
	}

	.content {
		padding: 27px 25px;
		height: 800px;
	}

	.table_head {
		height: 51px;
		background: #EEEEEE;

		.table_head_th {
			line-height: 51px;
			text-align: center;
			border-right: 1px solid #E5E5E5;
			font-size: 16px;
			color: #333333;
		}
	}

	.table_body {
		height: 640px;
		overflow-y: auto;

		.table_item {
			border-bottom: 8px solid #EEEEEE;

			.table_row {
				border: 1px solid #E5E5E5;
				border-right: none;
				height: 77px;

				.table_row_th {
					display: flex;
					align-items: center;
					// text-align: center;
					justify-content: center;
					flex-direction: column;
					height: 77px;
					border-right: 1px solid #E5E5E5;
					font-size: 14px;
					font-weight: 500;
					color: #333333;
					text-align: center;
				}
			}

			.btn_group {
				padding-right: 12px;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.el-dropdown {
					margin: 0 10px;
				}

				.btn1 {
					background: #F4AA2B;
					color: #fff;
				}

				.btn2 {
					background: #25B864;
					color: #fff;
				}

				.btn3 {
					background: #2E77F0;
					color: #fff;
				}

				.btn4 {
					background: #2EC0F0;
					color: #fff;
				}

				.btn5 {
					background: linear-gradient(163deg, #E51E0B 0%, #FF6759 100%);
					color: #fff;
				}

				.btn6 {
					background: #D2D2D2;
					color: #333333;
				}

				.btn7 {
					background: #E51E0B;
					color: #fff;
				}

				.btn8 {
					background: #E6A23C;
					color: #fff;
				}

				.a_btn {
					padding: 12px 20px;
					border-radius: 4px;
					font-size: 14px;
					line-height: 1;
					margin: 0 10px;
				}
			}
		}
	}

	.noTeacter {
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
